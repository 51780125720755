<template>
  <div class="col-12 c">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table-sm table table-hover table-bordered table-striped">
                <thead>
                    <th>
                        الحاج
                    </th>
                    <th>
                        رقم الحجز
                    </th>
                    <th>
                        التصنيف
                    </th>
                    <th>
                        تاريخ البلاغ
                    </th>
                    <th>
                        الحالة
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="ticket in tickets" :key="ticket._id" :class="ticket.timeagominutes >= 120 && ticket.status == 0 ? 'bg-light-danger' : ''">
                        <td>
                            {{ ticket.user.name }}
                        </td>
                        <td>
                            {{ ticket.user.order_id }}
                        </td>
                        <td>
                            {{ ticket.category }}
                        </td>
                        <td>
                            {{ ticket.timeago }}
                        </td>
                        <td>
                            <span class="btn btn-sm btn-warning" style="padding: 5px; border-radius: 0px" v-if="ticket.status == 0">
                                <i class="fa fa-clock-o"></i>
                                في الانتظار
                            </span>
                            <span class="btn btn-sm btn-success" style="padding: 5px; border-radius: 0px" v-if="ticket.status == 1">
                                <i class="fa fa-check"></i>
                                مغلقة
                            </span>
                        </td>
                        <td>
                            <button v-b-modal.details class="btn btn-sm btn-primary" @click="current = ticket" style="padding: 5px; border-radius: 0px">
                                <i class="fa fa-list"></i>
                                التفاصيل
                            </button>
                            <button class="btn btn-sm btn-success" v-if="(user.pers.includes('*') || user.pers.includes(ticket.category)) && ticket.status == 0" @click="endTicket(ticket)" style="padding: 5px; border-radius: 0px">
                                <i class="fa fa-check"></i>
                                اغلاق
                            </button>
                            <button class="btn btn-sm btn-outline-danger" v-if="user.pers.includes('*')" @click="deleteTicket(ticket)" style="padding: 5px; border-radius: 0px">
                                <i class="fa fa-trash"></i> حذف
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="details" title="التفاصيل" hide-footer>
        <div class="col-12 table-responsive" v-if="current._id">
            <table class="table table-bordered table-hover table-sm">
                <tbody>
                    <tr>
                        <td>
                            اسم المبلغ
                        </td>
                        <td>
                            {{ current.user.name }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            رقم الحجز
                        </td>
                        <td>
                            {{ current.user.order_id }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            مدينة القدوم
                        </td>
                        <td>
                            {{ current.user.city }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            رقم الحافلة
                        </td>
                        <td>
                            {{ current.user.bus_number }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            مشرف الحالفة
                        </td>
                        <td>
                            {{ current.user.bus_admin }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            رقم جواله
                        </td>
                        <td>
                            {{ current.user.phone }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            تفاصيل البلاغ
                        </td>
                        <td>
                            {{ current.content }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            تصنيف البلاغ
                        </td>
                        <td>
                            {{ current.category }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            tickets: [],
            current: {}
        }
    },
    created(){
        if(!checkPer('add-ticket')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        g.getList()
    },
    methods: {
        getList(){
            var g = this;
            $.post(api + '/admin/users/tickets', {
                jwt: g.user.jwt
            }).then(function(r){
                g.tickets = r.response;
            })
        },
        deleteTicket(ticket){
            var g = this;
            if(confirm("متأكد من حذف البلاغ؟")){
                $.post(api + '/admin/users/tickets/delete', {
                    jwt: g.user.jwt,
                    id: ticket._id
                }).then(function(r){
                    g.getList()
                })
            }
        },
        endTicket(ticket){
            var g = this;
            if(confirm("متأكد من اغلاق البلاغ؟")){
                $.post(api + '/admin/users/tickets/end', {
                    jwt: g.user.jwt,
                    id: ticket._id
                }).then(function(r){
                    g.getList()
                })
            }
        }
    }

}
</script>

<style>

</style>